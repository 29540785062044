/* global ng_fw ng_hs */
/** @jsxRuntime classic */
import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import "./utils/polyfills";

import React from "react";
import ReactDOM from "react-dom";

import * as serviceWorker from "./serviceWorker";
import { getFrameworkPreferences } from "./neo/neo-bridge/setup/aglc_setup";
import Neo from "./neo/neo-bridge/Neo";
import { log, warn } from "./utils/logger";

import "./index.css";
import App from "./components/App";
import { isMobileIOS } from "./utils/device";

let neo = new Neo();

ReactDOM.render(
    <React.StrictMode>
        <App neo={neo} />
    </React.StrictMode>,
    document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register();

window.getFrameworkPreferences = getFrameworkPreferences;

document.addEventListener("fwInitError", function () {
    warn("fwInitError");
    neo.initError();
});

document.addEventListener("fwReady", function () {
    log("fwReady");
    neo.init(ng_fw, ng_hs);

    setupUIActionsToCallNeoFramework();
});

function setupUIActionsToCallNeoFramework() {
    attachHandlerOnEvent("[data-action]", "click", function (e, target) {
        e.preventDefault();
        const actionName = target.getAttribute("data-action");
        const actionArgs = target.getAttribute("data-action-args");

        callNeoAction(actionName, actionArgs);
    });
}

function attachHandlerOnEvent(selector, eventType, handler) {
    document.addEventListener(
        eventType,
        function (e) {
            // loop parent nodes from the target to the delegation node
            for (let target = e.target; target && target !== this; target = target.parentNode) {
                if (target.matches(selector)) {
                    handler.call(target, e, target);
                    break;
                }
            }
        },
        false
    );
}

function callNeoAction(actionName, actionArgs) {
    let actualArguments = [];
    if (actionArgs) {
        try {
            actualArguments = [JSON.parse(actionArgs)];
        } catch (err) {
            actualArguments = actionArgs.split("|");
        }
    }
    log("calling ng_fw.", actionName, actualArguments);
    neo.neoClient[actionName](...actualArguments);
}

window.addEventListener("load", function () {
    function stopIOSFromZoomingDueNeoNon16pxInputs() {
        if (isMobileIOS(navigator.userAgent)) {
            let viewport = document.querySelector("meta[name=viewport]");
            viewport.setAttribute("content", "width=device-width, initial-scale=1.0, maximum-scale=1.0");
        }
    }

    stopIOSFromZoomingDueNeoNon16pxInputs();
});
