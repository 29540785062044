import Session from "./session/Session";
import { log, warn } from "../../utils/logger";
import { getInternalPageToOpen, shouldOpenPageInCurrentTab } from "./openPage/openPage";
import debounce from "lodash.debounce";

class Neo {
    constructor() {
        this.initDone = false;
        this.initErrorReceived = false;
        this.callbacks = {};
        this.playerSession = new Session();
        this.envConfig = {};
        this.doClientNavigate = () => {};
    }
    openSport = (container, options) => {
        return this.neoClient.openSport(container, options);
    };

    closeGame = (gameID, playMode) => {
        this.neoClient.closeGame(gameID, playMode);
    };

    loadGame = (gameID, playMode) => {
        log("ng_fw.loadGame", gameID, playMode);
        this.neoClient.loadGame(gameID, playMode);
    };
    init = (neoClient, neoClientInterfaces) => {
        this.neoClient = neoClient;
        this.neoClientInterfaces = neoClientInterfaces;
        this.playerSession.setRefreshSessionHandler(neoClient.keepAlive);
        this.startRefreshingSession();
        this.setupEvents();
        this.initDone = true;
        this.callCallbacks("onInit");
    };
    initError = () => {
        this.callCallbacks("onInitError");
        this.initErrorReceived = true;
    };

    startRefreshingSession = () => {
        if (this.neoClient.getSessionDetails()) {
            this.playerSession.start();
        }
    };

    setupEvents = () => {
        const that = this;
        log("setup Events");
        this.neoClientInterfaces.instantGame.gameLoadStart(function () {
            log("ng_hs.instantGame.gameLoadStart", arguments);
            that.callCallbacks("gameLoadStart", ...arguments);
        });
        this.neoClientInterfaces.instantGame.gameClosed(function () {
            log("ng_hs.instantGame.gameClosed", arguments);
            that.callCallbacks("gameClosed", ...arguments);
        });
        this.neoClientInterfaces.onBalanceUpdated(function () {
            log("ng_hs.onBalanceUpdated", arguments);
            that.callCallbacks("onBalanceUpdated", ...arguments);
        });
        this.neoClientInterfaces.onSessionStatusUpdate(function (update) {
            log("ng_hs.onSessionStatusUpdate", arguments);
            that.handleSessionStatusUpdate(update);
        });
        this.neoClientInterfaces.openPage(function () {
            log("ng_hs.openPage", arguments);
            that.handleOpenPage(...arguments);
        });
        this.neoClientInterfaces.clearURLParams(function () {
            log("ng_hs.clearURLParams", arguments);
            that.callCallbacks("clearURLParams", ...arguments);
        });
        this.neoClientInterfaces.onStateChange(function () {
            that.callCallbacks("onStateChange", ...arguments);
        });
        this.neoClientInterfaces.onGamesListUpdated(function () {
            log("ng_hs.onGamesListUpdated", arguments);
            that.callCallbacks("onGamesListUpdated", ...arguments);
        });
        this.neoClientInterfaces.shoppingCartStatusUpdated(function () {
            log("ng_hs.shoppingCartStatusUpdated", arguments);
            that.callCallbacks("shoppingCartStatusUpdated", ...arguments);
        });
    };

    handleSessionStatusUpdate = async (update) => {
        if (update.Status === "Login") {
            this.playerSession.start();
        } else {
            this.playerSession.stop();
        }
        this.callCallbacks("validatedOnSessionStatusUpdate", update);
    };

    validateToken = async (update) => {
        try {
            const response = await fetch("/api/vtl/validate-token", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    PlayerID: update.PlayerID,
                    SessionToken: update.SessionToken,
                }),
            });
            return await response.json();
        } catch (err) {
            return { errorCode: "Network Error" };
        }
    };

    setEnvConfig = (config) => {
        this.envConfig = config;
    };
    registerCallback = (callbackName, callback) => {
        if (!this.callbacks[callbackName]) {
            this.callbacks[callbackName] = [];
        }
        this.callbacks[callbackName] = [...this.callbacks[callbackName], callback];
    };
    removeCallback = (callbackName, callback) => {
        this.callbacks[callbackName] = this.callbacks[callbackName].filter((registeredCallback) => {
            return registeredCallback !== callback;
        });
    };
    callCallbacks = (callBackName, ...args) => {
        if (this.callbacks[callBackName]?.length > 0) {
            this.callbacks[callBackName].forEach((callback) => {
                callback(...args);
            });
        }
    };

    updateGamesList = debounce(() => {
        this.neoClient && this.neoClient.updateGamesList();
    }, 100);

    getUserBalanceToDisplay = () => {
        log("getUserBalanceToDisplay:", this.neoClient?.getDisplayBalance().DisplayBalance);
        return this.neoClient?.getDisplayBalance().DisplayBalance;
    };
    getSessionDetails = () => {
        return this.neoClient?.getSessionDetails();
    };
    logout = () => {
        this.neoClient.logout();
    };
    showDeposit = () => {
        this.neoClient.showDeposit();
    };
    showWithdraw = () => {
        this.neoClient.showWithdraw();
    };
    showMyAccount = () => {
        this.neoClient.showMyAccount();
    };
    showTransactionsHistory = () => {
        this.neoClient.showTransactionsHistory();
    };
    closeAllWidgets = () => {
        this.neoClient.closeAllWidgets();
    };
    handleOpenPage = (Key, CustomInfo) => {
        log(`openPage:|${Key}|CustomerInfo:|${CustomInfo}|`);
        const pagePath = getInternalPageToOpen(Key, CustomInfo, this.envConfig?.env?.games);
        log(`trying to open pagePath:${pagePath}`);

        if (pagePath) {
            if (shouldOpenPageInCurrentTab(Key, CustomInfo)) {
                this.doClientNavigate && this.doClientNavigate(pagePath);
                this.closeAllWidgets();
            } else {
                const a = document.createElement("a");
                a.href = pagePath;
                a.setAttribute("target", "_blank");
                a.setAttribute("rel", "noopener noreferrer");
                a.click();
            }
        }
    };
    getShoppingCartStatus = () => {
        return this.neoClient.getShoppingCartStatus();
    };
    getLatestDrawResults = async ({ gameId, isLatest, startRangeDate, endRangeDate }) => {
        const neoClient = this.neoClient;
        return new Promise((resolve) => {
            if (!neoClient || !neoClient.getLatestDrawResults) {
                return resolve(false);
            }
            neoClient.getLatestDrawResults({ gameId, isLatest, startRangeDate, endRangeDate }, (data) => {
                if (data.ErrorCode) {
                    return resolve({ error: data.ErrorMessage || data.ErrorCode });
                }
                resolve(processLatestDrawResults(data));
            });
        });
    };
    getNearestRetailersLocations = async ({ latitude, longitude, range, numberOfResults }) => {
        const neoClient = this.neoClient;
        return new Promise((resolve) => {
            if (!neoClient || !neoClient.getNearestRetailersLocations) {
                return resolve(false);
            }
            neoClient.getNearestRetailersLocations({ latitude, longitude, range, numberOfResults }, (data) => {
                if (data.ErrorCode) {
                    if (data.ErrorCode === 100003) {
                        return resolve({
                            error: "Sorry, Lotto Spot! locator is currently unavailable. Please try later.",
                        });
                    }

                    return resolve({ error: data.ErrorMessage || data.ErrorCode });
                }

                return resolve({ retailers: data.Retailers });
            });
        });
    };
    checkTicket = async ({ controlNumber }) => {
        const neoClient = this.neoClient;

        return new Promise((resolve) => {
            if (!neoClient || !neoClient.checkTicket) {
                return resolve(false);
            }
            neoClient.checkTicket({ controlNumber }, (data) => {
                if (data.ErrorCode) {
                    return resolve({ error: data.ErrorMessage || data.ErrorCode });
                }
                resolve(data);
            });
        });
    };

    getExternalProgressiveJackpots = async ({ externalProviderCode }) => {
        const neoClient = this.neoClient;
        return new Promise((resolve) => {
            if (!neoClient || !neoClient.getExternalProgressiveJackpots) {
                return resolve(false);
            }
            neoClient.getExternalProgressiveJackpots({ externalProviderCode }, (data) => {
                if (data.ErrorCode) {
                    return resolve({ error: data.ErrorMessage || data.ErrorCode });
                }
                resolve(data.ProgressiveJackpots);
            });
        });
    };
}

function processLatestDrawResults(data) {
    try {
        return {
            gameId: data.GameID,
            pastDrawResults: data.PastDrawResults.map((draw) => ({
                drawDateTime: draw.DrawDateTime,
                drawResult: JSON.parse(draw.DrawResult),
            })),
        };
    } catch (err) {
        warn("incompatible data from neo api", data, err);
        return false;
    }
}

export default Neo;
