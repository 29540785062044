import useSWR from "swr";
import useEnvConfig from "../hooks/useEnvConfig";

export function filterArchived(item) {
    if (Array.isArray(item)) {
        let arr = [];

        item.forEach((i) => {
            const element = filterArchived(i);
            if (element) {
                arr.push(element);
            }
        });

        return arr;
    }

    if (item && typeof item === "object") {
        if (item.archived) {
            return null;
        }

        for (const prop in item) {
            item[prop] = filterArchived(item[prop]);
        }
    }

    return item;
}

function fetcher(init, parseAs = "json") {
    return function (url) {
        return fetch(url, init)
            .then((x) => Promise.all([Promise.resolve(x.status), x[parseAs]()]))
            .then(([status, body]) => {
                let response = typeof body === "object" ? body : { body };
                response = filterArchived(response);

                return {
                    ...response,
                    status,
                };
            });
    };
}

export function useAuthedFetch(url, parseAs) {
    const { env } = useEnvConfig();
    return useSWR(
        url,
        fetcher(
            {
                headers: {
                    Authorization: `Bearer ${env.bearerToken}`,
                },
            },
            parseAs
        ),
        {
            revalidateOnFocus: false,
            revalidateOnReconnect: false,
        }
    );
}

export function useFetch(url) {
    const fullUrl = `${url}`;
    return useSWR(fullUrl, fetcher(), {
        revalidateOnFocus: false,
        revalidateOnReconnect: false,
    });
}

export function useNav() {
    return useAuthedFetch(`${process.env.REACT_APP_DOTCMS_BASE_URL}/api/v1/nav/?depth=2`);
}

export function useCMSPage(path) {
    return useAuthedFetch(`${process.env.REACT_APP_DOTCMS_BASE_URL}/api/v1/page/json${path}?language_id=1`);
}

export function useCMSWidget(id, parseAs) {
    return useAuthedFetch(`${process.env.REACT_APP_DOTCMS_BASE_URL}/api/widget/id/${id}`, parseAs);
}

export function useCMSContent(id, render = false) {
    let url = id
        ? `${process.env.REACT_APP_DOTCMS_BASE_URL}/api/content/id/${id}/depth/1${render ? "/render/true" : ""}`
        : null;
    return useAuthedFetch(url);
}

export function useCMSContentQuery({ type, slug, category, customFields, orderBy, depth }) {
    const searchUrl = [
        `${process.env.REACT_APP_DOTCMS_BASE_URL}/api/content/render/false/type/json/query/-contentType:forms`,
        type ? `+contentType:${type}` : null,
        `+(conhost:8a7d5e23-da1e-420a-b4f0-471e7da8ea2d`,
        `conhost:SYSTEM_HOST)`,
        type && slug ? `+${type}.urlTitle_dotraw:${slug}` : null,
        category ? (category === "winning-numbers" ? `+(categories:lottery)` : `+(categories:${category})`) : null,
        type && customFields ? [...customFields.map((field) => `+${type}.${field.name}:${field.value}`)] : null,
        `+languageId:1`,
        `+deleted:false`,
        `+working:true/orderby/${orderBy ? orderBy.split(" ").join("%20") : "score,modDate%20desc"}/limit/0`,
        depth > 0 ? `/depth/${depth}` : null,
    ]
        .filter(Boolean)
        .join("%20");
    return useAuthedFetch(searchUrl);
}

export function useEnvConfigApi() {
    return useFetch(`${process.env.REACT_APP_DOTCMS_BASE_URL}/api/vtl/env`);
}

export function useHelpCategoriesApi() {
    return useFetch(`${process.env.REACT_APP_DOTCMS_BASE_URL}/api/vtl/help-categories`);
}
