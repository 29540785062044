import { isMobile } from "../../../utils/device";

export function getFrameworkPreferences() {
    function getURLParams() {
        return window.location.search.replace(/^\?/, "");
    }
    return {
        BrandID: "128",
        Language: "ENG",
        Currency: "CAD",
        Platform: isMobile() ? "C" : "W",
        URLParams: getURLParams(),
        IsGameStandaloneMode: false,
        hooks: {},
        ExternalModules: {},
    };
}
